import accounting from 'accounting';

export function toHump(name: string) {
  return name.replace(/\_(\w)/g, (all, letter) => {
    return letter.toUpperCase();
  });
}
export function toLine(name: string) {
  return name.replace(/\B([A-Z])/g, '_$1').toLowerCase();
}
export function ObjectKeyToHump<T>(obj: T) {
  if (!obj) return {} as T;
  return Object.keys(obj as any).reduce((acc, curKey) => {
    acc[toHump(curKey)] = obj[curKey];
    return acc;
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  }, {} as T);
}

export function ObjectKeyToLine<T>(obj: T) {
  if (!obj) return {} as T;
  return Object.keys(obj as any).reduce((acc, curKey) => {
    acc[toLine(curKey)] = obj[curKey];
    return acc;
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  }, {} as T);
}

/**
 * @description 过滤掉对象中的 ''| [] | undefined 等值
 * @param obj Object
 *
 */
export const filterObject = (obj: Record<string, any>) => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    return isTruthy(value) ? { ...acc, [key]: value } : acc;
  }, {});
};

function isTruthy(value: any) {
  if (Array.isArray(value) && !value.length) {
    return false;
  }
  return Boolean(value);
}

export function paramToString(param: Record<string, any>, url: string) {
  if (!param) {
    return url;
  }
  const list: string[] = [];
  Object.keys(param).forEach((key) => {
    if (param.hasOwnProperty(key)) {
      list.push(
        encodeURIComponent(key) + '=' + encodeURIComponent(param[key] == null ? '' : param[key]),
      );
    }
  });
  if (list.length) {
    // eslint-disable-next-line no-param-reassign
    return (url += (~url.indexOf('?') ? '&' : '?') + list.join('&'));
  }
  return url;
}
function fixedZero(str: string | string[]): string {
  const strArr = Array.isArray(str) ? str : str.split('');
  if (!strArr.length) return '';
  const curNumber = strArr.pop();
  if (curNumber && Number(curNumber) !== 0) return [...strArr, curNumber].join('');
  return fixedZero(strArr);
}
export const convertPrise = (val: number | string, precision = 3): number => {
  const [start, end] = String(val).split('.');
  if (!end) return parseFloat(start);
  let thousand = end.substring(0, precision);
  thousand = fixedZero(thousand);

  return parseFloat(`${start}.${thousand}`);
};
export function formatMoney(money: any, precision = 3) {
  const integer = accounting.formatNumber(money || 0, precision).split('.')[0];
  const decimal = String(parseFloat(money || 0)).split('.')[1];
  return integer + (decimal ? `.${decimal}` : '');
}
