import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { getBaiduHm, env, getEnv } from '@/utils';
import Script from 'next/script';
// const isProd = process.env.NODE_ENV === 'production';

const Analytics = () => {
  const router = useRouter();
  const npsShow = [
    '/market/list',
    '/market/detail/[service]',
    '/market/mobile_sdk_detail/[service]',
    '/experience/[service]',
    '/document/[id]',
  ].includes(router.route);

  useEffect(() => {
    if (window.is_nps_open) {
      const closeBtn: any = document.querySelector('#nps-popup-close');
      if (closeBtn?.click) {
        closeBtn.click();
      }
    }
  }, [router.route]);

  const onLoadNps = () => {
    if (getEnv() === env.PRODUCTION) {
      // 正式环境
      window.npsmeter({
        key: '56ac4265ede28754',
        user_id: '', //替换成用户id
        user_name: '', //替换成用户名
      });
    } else {
      window.npsmeter({
        key: '417f4cdb6c2482d4',
        user_id: '', //替换成用户id
        user_name: '', //替换成用户名
      });
    }
  };

  return (
    <>
      <Script strategy="afterInteractive" src="/js/GrowingIO.js" />
      <Script strategy="afterInteractive" src="/js/zhugeIO.js" />
      {/* <Script
        src="https://static.textin.com/deps/sa-sdk-javascript-1.19.11/sensorsdata.min.js"
        onLoad={() => {
          if (!isProd) {
            window._gr_ignore_local_rule = true;
          }
          const sensors = window.sensorsDataAnalytic201505;
          sensors.init({
            server_url:
              'https://hehexinxikejipoc.datasink.sensorsdata.cn/sa?token=083fe5dc7b8664e2&project=default',
            heatmap: { scroll_notice_map: 'not_collect' },
            is_track_single_page: true,
            use_client_time: true,
            send_type: 'beacon',
          });
          sensors.quick('autoTrack');
        }}
      /> */}
      <Script src={getBaiduHm()} />
      {npsShow && <Script src="/js/nps.js" onLoad={onLoadNps} />}
      <Script strategy="afterInteractive" src="/js/leads.js" />
    </>
  );
};

export default Analytics;
