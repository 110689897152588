import { consultationTypeEnum, getContractUrl, getToken, target } from '@/utils';

export const contract_service = 'doc_compare';
export const contract_service_2 = 'doc_extraction';

export const toContract = () => {
  const contractURL = getContractUrl();
  const url = `/activate/contract?service=${contract_service}&redirect=${encodeURIComponent(
    contractURL,
  )}`;
  if (getToken()) {
    window.open(url);
  } else {
    window.open(`/user/login?redirect=${encodeURIComponent(url)}`);
  }
};

export const contractBannerData = {
  title: 'TextIn 合同机器人',
  desc: '基于TextIn深度学习识别技术和NLP算法，将合同签订前后文本进行精准比对和智能纠错，提高效率和控制风险。',
  backgroundImage: '/images/product/banner_contract.jpg',
  btnText: '在线试用',
};

export const contractVideoData = {
  title: '产品介绍',
  source: '//static.textin.com/videos/web/product/Textin_contract_robot.mp4',
};

export const contractPlatformSceneData = {
  title: '产品功能',
  sceneList: [
    {
      name: '多种格式文档比对',
      desc: '支持上传Word、PDF、图片格式文档进行比对，合同审批流程中最终版“电子档文件”与双方盖章的“用印扫描件”进行快速比对',
      image: '/images/contract/func_doc.png',
    },
    {
      name: '比对差异点精准标注',
      desc: '支持新增、修改、删除不同类型差异显示，帮助审计部门、法务部门、采购部门、销售部门快速准确审核合同用印前后是否一致',
      image: '/images/contract/func_diff.png',
    },
    {
      name: '文档全篇幅比对',
      desc: '支持跨页、跨行及全文比对，以全篇幅、整段落的方式进行比对，更贴近真实的合同比对场景。一键导出Excel差异报告，查看差异统计及详情',
      image: '/images/contract/func_all.png',
    },
    {
      name: '企业内部系统集成',
      desc: '支持与企业内部管理系统（如：ERP系统、OA系统、智能用印系统等）无缝对接，支持多种集成方式',
      image: '/images/contract/func_internal_system.png',
    },
  ],
};

export const contractAdvanceData = {
  title: '产品优势',
  backgroundImage: '/images/scene/pic_adv_bg.png',
  advanceList: [
    {
      icon: '/images/contract/icon_contract_adv_01.png',
      name: '高精度OCR识别',
      desc: '基于TextIn多项技术,依托海量标注数据样本训练，支持识别图片模糊、倾斜、反光等多种复杂场景，为客户提供高精度OCR识别',
    },
    {
      icon: '/images/contract/icon_contract_adv_02.png',
      name: '核心技术能力',
      desc: '基于智能文字识别、文档比对、自然语言处理等自研核心技术能力，打造AI新时代下的智能合比对工具，帮助企业实现合同智能审核',
    },
    {
      icon: '/images/contract/icon_contract_adv_03.png',
      name: '多种集成方式',
      desc: '支持公有云服务、私有化部署、API集成多种集成方式，支持与企业内部管理系统（如：ERP系统、OA系统等）无缝对接，支持多种集成方式',
    },
    {
      icon: '/images/contract/icon_contract_adv_04.png',
      name: '多行业适用',
      desc: '适用于金融、银行、制造业、医药 、通信、法律、审计、媒体、政府等50+行业，致力于优化企业合同流程，提高企业运营效率',
    },
  ],
};

export const contractSceneData = {
  title: '应用场景',
  sceneList: [
    {
      image: '/images/contract/pic_contract_scene_01@2x.png',
      name: '保险合同管理',
      desc: '帮助保险企业将OA系统中的过审合同与风险系统中的用印版合同进行比对，如存在差异即发出预警；将承办方合同与同类模板条款比对，提醒差异、缺少情况，规避合同伪造风险',
    },
    {
      image: '/images/contract/pic_contract_scene_02@2x.png',
      name: '银行信贷审批',
      desc: '帮助银行对租赁合同、经营实地场所、农村产权承包证等材料进行印章存在性判存、手写签字判存，对待审议合同与合同模板进行比对，判断条款是否有增、删、改',
    },
    {
      image: '/images/contract/pic_contract_scene_03@2x.png',
      name: '采购防范风险',
      desc: '帮助企业采购部门在前期洽谈合同环节中，防范“阴阳合同”、函证造假风险，识别纸质合同上的差异并展示差异结果',
    },
    {
      image: '/images/contract/pic_contract_scene_04@2x.png',
      name: '供应链金融合同审阅',
      desc: '供应链金融业务中，银行作为资金提供方，需要对合作伙伴提供的合同材料进行关键信息的抽取与审阅，用于贷款审核与风险管理',
    },
    {
      image: '/images/contract/pic_contract_scene_05@2x.png',
      name: '企业法务合规风控',
      desc: '为了防止用印合同与电子版合同存在关键性差异，对合同关键信息进行抽取与比对，防范重大合同风险，通过辅助法务人员审查合同，帮助法务人员从重复劳动中解放出来，提升审核专业水平',
    },
    {
      image: '/images/contract/pic_contract_scene_06@2x.png',
      name: '合同数据资产化管理',
      desc: '对合同关键信息进行识别抽取，生成指定字段数据，对接企业业务系统或存储入内部数据库，便于后续业务的数据追踪、履约情况管理，帮助企业洞察及研判经营状况，提升运营和数据治理能力',
    },
  ],
};

export const contractPartnerList = {
  image: '/images/contract/pic_customer_contract_bg@2x.png',
  partnerList: [
    {
      logo: '/images/contract/scene_logo_spd-bank_east@2x.png',
      companyInfo:
        '合合信息TextIn合同机器人对作为AI智能合同审阅专家，帮助法务部门进行合同比对，规避用印合同与电子版合同存在差异带来的损失风险，助力企业提升运营效率。',
      companyName: '—— 东方希望',
    },
    {
      logo: '/images/contract/scene_logo_spd-bank_tigermed@2x.png',
      companyInfo:
        '合合信息TextIn合同机器人将合同文本进行自动识别与精准智能比对，快速展示差异结果，从而帮助泰格医药降低合同风险，避免重大损失，同时提升工作效率，减少重复劳动，降低用人成本。',
      companyName: '—— 泰格医药',
    },
    {
      logo: '/images/contract/scene_logo_spd-bank_ccb@2x.png',
      companyInfo:
        '人工比对合同容易疏忽细微差异，依赖审核人员个体的业务素养、体力、精神状态，准确率无法保证，业务方或合约方可能篡改合同，导致合同风险与重大损失。建信信托引入合合信息TextIn合同机器人，为客户提供私有化部署方式，保证合同文档安全性，集成客户方业务系统，采用单点登录集成方式。',
      companyName: '—— 建信信托',
    },
  ],
};

export const deployList = [
  {
    title: '公有云API',
    logo: '/images/scene/pic_deploy_open-cloud_01@2x.png',
    desc: '16年技术沉淀，安全、可靠、迅速的公有云服务，可直接调用API使用TextIn智能文档识别产品',
    anchors: [{ text: '立即体验' }],
    cardLink: toContract,
    target: target._blank,
  },
  {
    title: '私有化部署',
    logo: '/images/scene/pic_deploy_open-cloud_02@2x.png',
    desc: '可部署至本地服务器，进一步保障数据安全。支持 GPU 环境及国产化操作系统部署',
    anchors: [{ href: `/contact?type=${consultationTypeEnum.TEXTIN_DEPLOY}`, text: '提交需求' }],
    cardLink: `/contact?type=${consultationTypeEnum.TEXTIN_DEPLOY}`,
  },
];
