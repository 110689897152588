/**
 * 替换文件后缀名
 */
export function replaceFileSuffixName(name: string, newExt: string) {
  return name.replace(/\.\w+$/, '.' + newExt);
}

export const generateUUID = (): string => {
  let dateData = new Date().getUTCDate();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c: string) => {
    const randomData = (dateData + Math.random() * 16) % 16 | 0;
    dateData = Math.floor(dateData / 16);
    return (c === 'x' ? randomData : (randomData & 0x3) | 0x8).toString(16);
  });
  return uuid;
};

const SPLIT_COMM = '__**TEXTIN**__';

export const getFileNameAndType = (name: string) => {
  const [fileName, type] = name.replace(/(.)\.(\w+)$/, `$1${SPLIT_COMM}$2`).split(SPLIT_COMM);
  return { fileName, type: type?.toLowerCase() };
};

export function blobToDataURL(file: Blob) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      resolve(e.target?.result as string);
    };
    reader.onerror = () => {
      // eslint-disable-next-line prefer-promise-reject-errors
      reject('资源加载失败');
    };
  });
}
export function blobToArrayBuffer(file: Blob) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      resolve(e.target?.result as string);
    };
    reader.onerror = () => {
      // eslint-disable-next-line prefer-promise-reject-errors
      reject('资源加载失败');
    };
  });
}
export function urlToBlob(url: string) {
  return new Promise<{
    blob: Blob;
    mine: string;
  }>((resolve, reject) => {
    const image = new Image();
    image.onload = function () {
      const canvas = document.createElement('canvas');
      canvas.width = image.naturalWidth;
      canvas.height = image.naturalHeight;
      // 将图片插入画布并开始绘制
      canvas.getContext('2d')!.drawImage(image, 0, 0);
      const ext = image.src.substring(image.src.lastIndexOf('.') + 1).toLowerCase();
      // result
      const result = canvas.toDataURL(`image/${ext}`);
      resolve({
        blob: base64ToBlob(result),
        mine: ext,
      });
    };
    image.setAttribute('crossOrigin', 'Anonymous');
    image.src = url;
    // 图片加载失败的错误处理
    image.onerror = () => {
      reject(new Error('urlToBlob error'));
    };
  });
}
export const addDataToURL = (data: string, mime: string) => `data:image/${mime};base64,${data}`;
export const isBase64String = (str: string) => {
  const base64Reg = /^data:.*?;base64,/;
  return base64Reg.test(str);
};
// export function readAsBlob(file){

// }

export const trimDataToURL = (data: string) => data.replace(/^data:.*?;base64,/, '');

const base64ToBuffer = (base64: string) => {
  const binary = atob(trimDataToURL(base64));
  const { length } = binary;
  const buffer = new Uint8Array(length);
  for (let i = 0; i < length; i++) {
    buffer[i] = binary.charCodeAt(i) & 0xff;
  }
  return buffer;
};

export const base64ToBlob = (base64: any, type = 'application/octet-stream') => {
  return new Blob([base64ToBuffer(base64)], {
    type,
  });
};

export const suffixMapMIME = {
  png: 'image/png',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  bmp: 'image/bmp',
  tiff: 'image/tiff',
  tif: 'image/tiff',
  gif: 'image/gif',
  pdf: 'application/pdf',
};

export function getAcceptType(accept) {
  const type = accept.replace(/\./g, '');
  return suffixMapMIME[type] || (type.includes('.') ? type : `.${type}`);
}

export function getAccept(accept?: string | any[]) {
  if (!accept) return '';
  if (Array.isArray(accept)) {
    const acceptList = accept.filter((type) => type).map((type) => getAcceptType(type));
    return [...new Set(acceptList)];
  } else {
    return getAcceptType(accept);
  }
}
export const getExtension = (name: string) => {
  const extension = name.indexOf('.') > -1 ? `.${name.split('.').pop()?.toLowerCase()}` : '';
  return extension;
};
export function checkType(file, accept = '') {
  if (!file) return false;
  if (accept.length === 0) return true;
  const { type, name } = file;
  const acceptStr = Array.isArray(accept) ? accept.join() : accept;
  const extension = name.indexOf('.') > -1 ? `.${name.split('.').pop()?.toLowerCase()}` : '';
  if (acceptStr.includes('image/*') && type.includes('image')) return true;
  return acceptStr
    .toLowerCase()
    .split(',')
    .map((type) => type?.trim())
    .filter((type) => type)
    .some((acceptMIME) => type === acceptMIME || acceptMIME === extension);
}
