export const track = function ({
  name,
  keyword,
  userId,
  ...rest
}: {
  [key: string]: any;
  name: string;
  keyword: string;
  userId?: string;
}) {
  if (window.zhuge) {
    window.zhuge.track(name, { keyword: keyword, user_id: userId, ...rest });
  }
};
